<template>
  <div class="overview">
    <div
      v-if="sitesByStatus.length === 0"
      class="text--secondary py-5 px-5 all-sites-ok"
    >
      <v-icon>fa-check-circle</v-icon>
      <small>{{ $t("all_sites_ok") }}</small>
    </div>

    <div v-if="sitesByStatus.length > 0" class="mx-5 my-8">
      <v-card
        v-for="site in sitesByStatus"
        :key="site.id"
        class="mb-5 px-5 py-3"
      >
        <v-row>
          <v-col cols="12" md="3">
            <v-chip :class="`badge badge-small badge-${site.status}`" x-small>
              {{ $t(`severity_${site.status}`) }}
            </v-chip>
            <div>
              <v-chip
                label
                outlined
                @click="
                  $router.push({
                    name: 'NetworkSiteDetails',
                    params: { siteId: site.id },
                  })
                "
              >
                {{ site.name }}
              </v-chip>
              <small class="text--disabled">
                {{ site.numberOfAlerts }}
                {{ $tc("alarms", site.numberOfAlerts).toLowerCase() }}
              </small>
            </div>
          </v-col>
          <v-col v-for="alert in site.alerts" :key="alert.id" cols="12" md="2">
            <alert
              :alert="alert"
              :margin="1"
              :show-site-name="false"
              :show-reason="false"
              small-badge
            ></alert>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { cloneDeep, orderBy, sortBy } from "lodash-es";
import { mapGetters, mapState } from "vuex";
import Alert from "@/components/Global/Alert.vue";

export default {
  name: "Overview",
  components: {
    Alert,
  },
  computed: {
    ...mapGetters(["alertsForSite"]),
    ...mapState({
      sites: (state) => state.site.sites,
    }),
    sitesByStatus() {
      const sites = cloneDeep(
        this.sites.filter((site) => site.status !== "none")
      );

      sites.forEach((site) => {
        const alerts = this.alertsForSite(site.id).filter(
          (alert) => alert.type !== "none"
        );

        site.numberOfAlerts = alerts.length;

        // Create composite key to sort based on site status and number of alerts
        // e.g. 1_0012_0003_0000
        //      |    |    |    |- number of serious alerts
        //      |    |    |------ number of warning alerts
        //      |    |----------- number of critical alerts
        //      |---------------- severity (0: warning, 1: serious,  2:critical)

        let key = ["warning", "serious", "critical"].indexOf(site.status);

        ["critical", "warning", "serious"].forEach((severity) => {
          const numberOfAlerts = alerts.filter(
            (alert) => alert.type === severity
          ).length;
          key += `_${String(numberOfAlerts).padStart(4, "0")}`;
        });

        site.key = key;

        // Show only 4 most imported alerts
        site.alerts = sortBy(alerts, (alert) =>
          ["critical", "serious", "warning"].indexOf(alert.type)
        ).slice(0, 4);
      });

      // Reverse to put sites with most important status and most number of alerts first
      return orderBy(sites, ["key"], ["desc"]);
    },
  },
};
</script>
